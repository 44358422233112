import * as React from "react"
import {Typography} from "@mui/material";
import BlogPost from "../../components/blog-post";


// markup
const BlogPostPage = () => {
    return (
        <BlogPost title={'Why use jury selection software?'} subtitle={'Feb 24, 2021'}>
            <br/>
            <Typography>Selecting a quality jury is key to winning your case. If you are still using sticky notes to
                organize your notes for jury selection, you may want to read this post about how jury selection software
                can enhance your jury selection process.</Typography>
            <br/>
            <Typography variant={"h5"} color={"primary"}>Collaborative note taking</Typography>
            <Typography>Jury selection is a fast paced activity. You are asking questions, taking notes, and reading
                body language all at the same time. Collaborative jury selection software, like Jurybox, allows you to
                have multiple people taking juror notes at the same time. That means you can focus your attention on the
                subtleties of juror body language, while an assistant or co-counsel can take detailed notes. Those notes
                can be referenced by you in real time. No need to pass papers or whisper. It's all there as soon as you
                enter it in the system.</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Detailed juror info</Typography>
            <Typography>Taking notes on stickies is limited to 15-20 words. Virtual sticky notes, like those in Jurybox,
                have no limit, allowing you to have a much more thorough record of the juror details.</Typography>
            <br/>
            <Typography>In addition to notes, visual indicators like, thumbs up or down, let you quickly give an
                impression about a juror. So, at a glance you can remember how you feel about a juror. In addition, the
                ability to highlight specific info really helps to make key info pop.</Typography>
            <br/>
            <Typography variant={"h5"} color={"primary"}>Questionnaire details at your finger tips</Typography>
            <Typography>Most jury trials include a juror questionnaire that is given out and collected. Typically, this
                info would come as a stack of papers that you would have to rifle through along with your sticky notes.
                This is very tedious and impractical given the intense nature of jury selection. With software like
                Jurybox, you can enter that data into the system before the trial and then promote a juror to the box
                with the questionnaire details included, thereby merging your questionnaire info with your sticky notes.
                That way, all the info you track about a juror is together in one juror card.</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>No more mistakes in tracking challenges</Typography>
            <Typography>With so much going on during jury selection, it's difficult to keep track of the number of
                jurors that have been dismissed in each category. If a mistake is made in counting the number of
                peremptory challenges for the prosecution or defense, it could really mess up your dismissal strategy.
                Solutions like Jurybox track these challenges automatically so mistakes like that don't
                happen.</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Summary</Typography>
            <Typography>There are many reasons why more and more attorneys are relying on jury selection software to
                help them select a quality jury. Collaboration, enhanced note taking, and mistake proofing are just a
                few. If you are still skeptical, give Jurybox a <a href={"https://app.juryboxapp.com/signup"}>try</a>. The first three days are free, no credit card
                required!</Typography>
        </BlogPost>
    );
};

export default BlogPostPage;
